import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles/reset.scss';
import './assets/styles/border.scss';
import './assets/styles/swiper.min.css'
import './assets/styles/certify.css'

Vue.config.productionTip = false;
if (["development", "test"].indexOf(process.env.VUE_APP_MODE) > -1) {
    import("vconsole").then((res) => {
      // eslint-disable-next-line new-cap
      new res.default();
      console.log('navigator.userAgent', navigator.userAgent);
    });
}

router.beforeEach((to, from, next) => {
	// chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');
