<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
  </div>
</template>

<script>
import './styles/index.scss'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    
  },
  mounted() {
    
  }
}
</script>

<style lang="scss">
body,
html {
  height: 100%;
}

#app {
  height: 100%;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
